<template>
	<div style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%;  ">
			<div class="popup type03" style="width: 730px; margin: 100px auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div>
				<div class="p-header">
						<div class="title">가맹점 한도 부여 정책</div>
						<div class="btn-close_popup" @click="close">닫기</div>
					</div>
					<div class="p-body" style="">
						<div class="c01">
							<div class="p1">보증보험 금액 선정 시 가맹점의 매출규모에 따라 다릅니다.</div>
						</div>
						<div class="c02" style="padding: 0; margin: 10px 0 0; ">
							<div class="table type05" > 
								<div class="item-header" style="height: 58px; overflow-y: scroll;">
									<div class="item">
										<div class="td">보증보험 금액</div>
										<div class="td">1회 한도</div>
										<div class="td">월 한도</div>
										<div class="td">년 한도</div>
										<div class="td">한도 선택</div>
									</div>
								</div>
								<div class="item-list" style="height: 550px; overflow: auto;">
									<div class="item"
										v-for="(item, index) in insurance"
										:key="index"
									>
										<div class="td">{{ item.insuranceCoverageAmountText }}</div>
										<div class="td">{{ item.oneTimeLimitText }}</div>
										<div class="td">{{ item.monthLimitText }}</div>
										<div class="td">{{ item.yearLimitText }}</div>
										<div class="td"><v-btn color="success" @click="setInsurance(index)" style="background-color:#00bfa5 ;" small>선택</v-btn></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'FranchiseePopupLimit'
		,props: ['insurance']
		,methods: {
			close: function(){
				this.$emit('setOverlay', false)
			}
			,setInsurance: function(index){
				this.$emit('setInsurance', index)
			}
		}
	}
</script>