<template>
	
	<div class="cont" id="p17">
            <div class="page-title">가맹점 등록</div>
			
            <div class="c-list">
                <div class="c01">
                    <div class="s01">
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title">신청 구분</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content w100">
									<div class="si-title">신청구분</div>
									<div class="si-desc setting">
										<div class="cont-radio"
											v-for="(type, index) in companyType"
											:key="index"
										>
											<input type="radio" 
												v-model="item.companyType"
												:value="type.code"
												:id="'rd0' + index"
											>
											<label :for="'rd0' + index">{{ type.name }}</label>
										</div>
									</div>
                                </div>
								
                                <div class="s-content w100">
                                    <div class="si-title">아이디</div>
										<div class="si-desc setting">
										<div class="cont-radio">
											<input type="radio" id="rd04" name="radio_status2" value="true" checked
												v-model="item.isAutoUserId">
											<label for="rd04">자동생성</label>
										</div>
										<div class="cont-radio">
											<input type="radio" id="rd05" name="radio_status2" value="false"
												v-model="item.isAutoUserId"
											>
											<label for="rd05">직접입력</label>
										</div>
										<div class="dt">
											<input class="w150p" type="text" placeholder="요청 아이디 입력"
												v-model="item.userId"
											>
											<a @click="idCheck(item.userId)">
												<div class="btn-type01"
												:class="idCheckStatus"
												>중복체크</div></a>
											<span class="err_id active"
												v-show="idCheck.result">사용중인 아이디입니다.</span> <!-- error시 class "active" -->
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s-item corporate_business active"> <!-- 법인/개인 사업자 class "active" -->
                            <div class="s-header">
                                <div class="s-title">사업자 정보</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">상호<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="상호명 입력"
											v-model="item.businessName"
											:rules="[rules.max(item, 'businessName', 50)]"
										>
                                    </div>
                                </div>
                                <div class="s-content"
								>
                                    <div class="si-title"
									v-show="item.companyType != 'NON'">법인등록번호</div>
                                    <div class="si-desc"
									v-show="item.companyType != 'NON'">
                                        <input type="number" placeholder="법인등록번호 입력"
											v-model="item.corporateNumber"
											:rules="[rules.max(item, 'corporateNumber', 13)]"
										>
                                    </div>
                                </div>
                                <div class="s-content" 
									v-show="item.companyType != 'NON'"
								>
                                    <div class="si-title">사업자등록번호<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="number" placeholder="사업자등록번호 입력"
											v-model="item.businessNumber"
											:rules="[rules.max(item, 'businessNumber', item.companyType == 'NON' ? 13 : 10)]"
										>
										
                                    </div>
                                </div>
                                <div class="s-content"
									v-show="item.companyType != 'NON'"
								>
                                    <div class="si-title"
									v-show="item.companyType != 'NON'">팩스</div>
                                    <div class="si-desc"
									v-show="item.companyType != 'NON'">
                                        <input type="number" placeholder="-를 제외한 숫자 입력"
											v-model="item.faxNumber"
											:rules="[rules.max(item, 'faxNumber', 14)]"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">대표전화<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="number" placeholder="-를 제외한 숫자 입력"
											v-model="item.phoneNumber"
											:rules="[rules.max(item, 'phoneNumber', 14)]"
										>
                                    </div>
                                </div>
                                <div class="s-content"
								>
                                    <div class="si-title"
										v-show="item.companyType != 'NON'"
									>업태</div>
                                    <div class="si-desc"
										v-show="item.companyType != 'NON'"
									>
                                        <input type="text" placeholder="업태 입력"
											v-model="item.businessType"
											:rules="[rules.max(item, 'businessType', 20)]"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">이메일<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="email" placeholder="이메일 입력"
											v-model="item.companyEmail"
											:rules="[rules.max(item, 'companyEmail', 50)]"
										>
                                    </div>
                                </div>
                                <div class="s-content"
								>
                                    <div class="si-title"
									v-show="item.companyType != 'NON'">종목</div>
                                    <div class="si-desc"
									v-show="item.companyType != 'NON'">
                                        <input type="text" placeholder="종목 입력"
											v-model="item.businessCate"
											:rules="[rules.max(item, 'businessCate', 20)]"
										>
                                    </div>
                                </div>
                                <div class="s-content" style="display: none;">
                                    <div class="si-title">취급상품<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="취급상품 입력"
											v-model="item.productName"
											:rules="[rules.max(item, 'productName', 20)]"
										>
                                    </div>
                                </div>
                                <div class="s-content" style="display: none;">
                                    <div class="si-title">홈페이지</div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="홈페이지 주소"
											v-model="item.homePage"
											:rules="[rules.max(item, 'homePage', 50)]"
										>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">사업장 주소</div>
                                    <div class="si-desc addr">
										<a @click="daumPost('company')"><div class="btn-type01 info">주소검색</div></a>
										<input class="addr1" type="text" placeholder="우편번호"
											v-model="item.zipCode"
											@click="daumPost('company')"
											readonly
										>
										<input class="addr2" type="text" placeholder="주소"
											v-model="item.addressMain"
											@click="daumPost('company')"
											readonly
										>
										<input class="addr3" type="text" placeholder="상세주소를 입력하세요."
											v-model="item.addressDetail"
											maxlength="50"
											:rules="[rules.max(item, 'addressDetail', 100)]"
										>
                                    </div>
                                </div>
                                <div class="s-content"  style="display: none;"
								>
                                    <div class="si-title flex-column">
                                        <div>실소유자<span class="em">*</span></div>
                                        <div>성명</div>
                                    </div>
                                    <div class="si-desc">
                                        <input class="w150p" type="text" placeholder="성명 입력"
											v-model="item.realOwnerName "
											:rules="[rules.max(item, 'realOwnerName', 20)]"
										>
                                    </div>
								</div>
                                <div class="s-content"  style="display: none;"
								>
                                    <div class="si-title flex-column">
                                        <div>실소유자<span class="em">*</span></div>
                                        <div>생년월일</div>
                                    </div>
                                    <div class="si-desc">
                                        <input class="w150p" type="number" placeholder="예)19900815"
											v-model="item.realOwnerBirth"
											:rules="[rules.max(item, 'realOwnerBirth', 8)]"
										>
                                    </div>
                                </div>
                                <div class="s-content w100"  style="display: none;"
								>
                                    <div class="si-title flex-column">
                                        <div>실소유자<span class="em">*</span></div>
                                        <div>전화번호</div>
                                    </div>
                                    <div class="si-desc">
                                       <select name="" id=""
											v-model="phoneNumbers.owner.fir"
										>
											<option
												v-for="(number, index) in numbers"
												:key="index"
												:value="number"
											>{{ number }}</option>
										</select>
										<input class="w110p" type="number" placeholder="" 
											v-model="phoneNumbers.owner.sec"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											maxlength="4"
											:rules="[rules.max(phoneNumbers.owner, 'sec', 4)]"
										>
										<input class="w110p" type="number" placeholder=""
											v-model="phoneNumbers.owner.thi"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											:rules="[rules.max(phoneNumbers.owner, 'thi', 4)]"
										>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s-item"
							v-if="item.companyType == 'NON'">
                            <div class="s-header">
                                <div class="s-title">대표자 정보</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">대표자명<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="대표자명 입력"
											v-model="item.ceoName"
											:rules="[rules.max(item, 'ceoName', 20)]"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title" 
									v-show="item.companyType != 'NON'">생년월일<span class="em">*</span></div>
                                    <div class="si-desc"
									v-show="item.companyType != 'NON'">
                                        <input type="number" placeholder="생년월일 8자리 입력"
											v-model="item.ceoBirth"
											:rules="[rules.max(item, 'ceoBirth', 8)]"
										>
                                    </div>
                                </div>
								
                                <div class="s-content w100" 
									v-show="item.companyType == 'NON'"
								>
                                    <div class="si-title">주민등록번호<span class="em">*</span></div>
                                    <div class="si-desc">
										<input type="number" placeholder="앞번호 6자리 (숫자만 입력하세요)"
											v-model="personalNumber.fir"
											:rules="[rules.max(personalNumber, 'fir', 6)]"
											style="width: 49%; margin-right: 5px;"
											>
										<input type="password" placeholder="뒷번호 7자리 (숫자만 입력하세요)"
											v-model="personalNumber.sec"
											:rules="[rules.max(personalNumber, 'sec', 7), rules.numeric(personalNumber, 'sec')]"
											style="width: 50%;"
											>
									</div>
								</div>
                                <div class="s-content w100">
                                    <div class="si-title">대표자 휴대폰<span class="em">*</span></div>
                                    <div class="si-desc">
										<select name="" id=""
											v-model="phoneNumbers.ceo.fir"
										>
											<option
												v-for="(number, index) in numbers"
												:key="index"
												:value="number"
											>{{ number }}</option>
										</select>
										<input class="w110p" type="number" placeholder="" 
											v-model="phoneNumbers.ceo.sec"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											maxlength="4"
											:rules="[rules.max(phoneNumbers.ceo, 'sec', 4)]"
										>
										<input class="w110p" type="number" placeholder=""
											v-model="phoneNumbers.ceo.thi"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											:rules="[rules.max(phoneNumbers.ceo, 'thi', 4)]"
										>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title">담당자 정보</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">담당자<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="담당자 입력"
											v-model="item.managerName"
											:rules="[rules.max(item, 'managerName', 20)]"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">이메일<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="이메일 입력"
											v-model="item.managerEmail"
											:rules="[rules.max(item, 'managerEmail', 50)]"
										>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">담당자 휴대폰<span class="em">*</span></div>
                                    <div class="si-desc">
										<select name="" id=""
											v-model="phoneNumbers.manager.fir"
										>
											<option
												v-for="(number, index) in numbers"
												:key="index"
												:value="number"
											>{{ number }}</option>
										</select>
										<input class="w110p" type="number" placeholder="" 
											v-model="phoneNumbers.manager.sec"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											maxlength="4"
											:rules="[rules.max(phoneNumbers.manager, 'sec', 4)]"
										>
										<input class="w110p" type="number" placeholder=""
											v-model="phoneNumbers.manager.thi"
											style="padding: 6px 5px 7px !important; width: 100px !important; margin-left: 3px ;"
											:rules="[rules.max(phoneNumbers.manager, 'thi', 4)]"
										>
                                    </div>
                                </div>
                            </div>
                        </div>
						
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title" style="margin-top: 5px !important;">보증보험</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content w100">
                                    <div class="si-title">보증보험</div>
                                    <div class="si-desc">
                                        <select class="w185p" 
											v-model="item.insuranceCoverageAmount"
											@click="setInsurance2(item.insuranceCoverageAmount)"
										>
                                            <option value="">금액을 선택하세요.</option>
                                            <option 
												v-for="(ins, index) in codes.insurances"
												:key="index"
												:value="ins.insuranceCoverageAmount"
											>{{ ins.insuranceCoverageAmountText }}</option>
                                        </select>
										<v-btn color="primary" small text style="float: right; background-color: #2E9AFE !important; color: white; " @click="viewPopupLimit">보증보험 한도 바로가기 <v-icon small>mdi-arrow-top-right</v-icon></v-btn>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">이용한도<span class="em">*</span></div>
                                    <div class="si-desc limit">
                                        <div class="lm-set">
                                            <span class="p">1회</span>
                                            <input class="disable" type="number" placeholder="0원" readonly
												v-model="item.oneTimeLimit"
											>
                                        </div>
                                        <div class="lm-set">
                                            <span class="p">월</span>
                                            <input class="disable" type="number" placeholder="0원" readonly
												v-model="item.monthLimit">
                                        </div>
                                        <div class="lm-set">
                                            <span class="p">연</span>
                                            <input class="disable" type="number" placeholder="0원" readonly
												v-model="item.yearLimit">
                                        </div>
                                    </div>
                                </div>
								
                                <div class="s-content w100 hauto">
                                    <div class="si-title">이용서비스<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <div class="sv"
											v-for="(cate, index) in payChnCate"
											:key="index"
										>
                                            <div class="sv-tit">
                                                <div class="cont-check">
                                                    <input type="checkbox" name="checkbox_service"
														v-model="cate.use"
														:value="cate.code"
														:id="'ck' + cate.code" 
													>
                                                    <label 
														:for="'ck' + cate.code"><span>{{ cate.name }}</span></label>
                                                </div>
                                            </div>
                                            <div class="sv-item">
                                                <input id="input-ck1" type="number"
													v-model="cate.value"
													:readonly="cate.use ? false : true"
													:class="{disable: !cate.use}" 
													:rules="[rules.demical(cate, 'value', {min: 2, max: 2})]"
											>%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title">서비스 신청정보</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">정산주기<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <select class="w100" name="" id=""
											v-model="item.settlementCycle">
                                            <option 
												v-for="(cycle, index) in codes.settlementCycle"
												:key="index"
												:value="cycle.code"
											>{{ cycle.codeName }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title flex-column">
                                        <div>세금계산서<span class="em">*</span></div>
                                        <div>받을 이메일</div>
                                    </div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="이메일 입력"
											v-model="item.billEmail "
											maxlength="50"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">대금정산 거래은행<span class="em">*</span></div>
                                    <div class="si-desc">
										<select name="" id="" class="w100"
											v-model="item.bankCode"
										>
											<option value="">은행 선택</option>
											<option 
												v-for="(code, index) in codes.bankCodes"
												:key="index"
												:value="code.code"
												>{{code.codeName}}</option>
										</select>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">대금정산 계좌번호<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="number" placeholder="계좌번호 입력"
											v-model="item.bankAccountNumber"
											:rules="[rules.max(item, 'bankAccountNumber', 14)]"
										>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">예금주<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input class="w185p" type="text" placeholder="예금주 입력"
											v-model="item.bankAccountHolder"
											maxlength="12"
										>
                                    </div>
                                </div>
							</div>
						</div>
						
                        <div class="s-item" style="display: none;">
                            <div class="s-header">
                                <div class="s-title">판매자 상품 내역</div>
                            </div>
                            <div class="s-body">
                                <div class="s-content">
                                    <div class="si-title">서비스 구분<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <select class="w100" name="" id=""
											v-model="item.servicesType">
                                            <option value="">서비스 선택하세요.</option>
                                            <option 
												v-for="(service, index) in codes.servicesTypes"
												:key="index"
												:value="service.code"
											>{{ service.codeName }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">사용 용도<span class="em">*</span></div>
                                    <div class="si-desc">
                                        <input type="text" placeholder="사용용도 입력"
											v-model="item.serviceUsage"
										>
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">결제 금액 1회<span class="em">*</span></div>
                                    <div class="si-desc flex-center">
                                        <input type="text" placeholder="0" class="mr-5"
											v-model="item.oneTimePaymentAmount"
										>원
                                    </div>
                                </div>
                                <div class="s-content">
                                    <div class="si-title">결제 금액 월 평균<span class="em">*</span></div>
                                    <div class="si-desc flex-center">
                                        <input type="text" placeholder="0" class="mr-5"
											v-model="item.averageMonthlyPaymentAmount"
										>원
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">판매 상품 사진<span class="em">*</span></div>
                                    <div class="si-desc flex-aic">
                                        <div class="input-file">
											<v-file-input
												v-model="item.productImageFile"
												
												accept="image/png, image/jpeg, image/bmp"
												id="file01" 
												style="display: none;"
											></v-file-input>
                                            <label class="btn-type01" for="file01">첨부파일</label>
                                        </div>
										<div
											v-if="item.productImageFile"
											>{{ item.productImageFile.name }}</div>
                                        <div class="ex"
											v-if="!item.productImageFile">jpg, png만 지원 가능합니다.</div>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title">특이사항</div>
                                    <div class="si-desc flex-center">
                                        <input type="text" placeholder="특이사항 입력"
											v-model="item.memo"
										>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s-item">
                            <div class="s-header">
                                <div class="s-title" style="width: 30%;">구비서류 {{ companyTypeText }}</div>
                                <div class="s-link"></div>
                            </div>
                            <div class="s-body">
                                <div class="s-content w100"
									v-if="item.companyType != 'NON'"
								>
                                    <div class="si-title w220p">
                                        사업자등록증<span class="em">*</span>
                                    </div>
                                    <div class="si-desc flex-aic">
                                        <div class="input-file">
                                            
											<v-file-input
												v-model="item.businessRegistration"
												
												accept="image/png, image/jpeg, image/bmp"
												id="file02" 
												style="display: none;"
											></v-file-input>
                                            <label class="btn-type01" for="file02">첨부파일</label>
                                        </div>
										<div
											v-if="item.businessRegistration"
											>{{ item.businessRegistration.name }}</div>
                                        <div class="ex"
											v-if="!item.businessRegistration">jpg, png만 지원 가능합니다.</div>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title flex-column w220p">
                                        <div>대표자 신분증 or 주민등록등본<span class="em">*</span></div>
                                        <div v-if="item.companyType == 'PUBLIC'">(공동대표일 경우 모두 필요)</div>
                                    </div>
                                    <div class="si-desc flex-aic">
                                        <div class="input-file">
                                            
											<v-file-input
												v-model="item.copyOfIdCard"
												
												accept="image/png, image/jpeg, image/bmp"
												id="file03" 
												style="display: none;"
												
											></v-file-input>
											
                                            <label class="btn-type01" for="file03">첨부파일</label>
                                        </div>
										<div
											v-if="item.copyOfIdCard"
											>{{ multipleSummary(item.copyOfIdCard) }}</div>
                                        <div class="ex"
											v-if="!item.copyOfIdCard">jpg, png만 지원 가능합니다.</div>
                                    </div>
                                </div>
                                <div class="s-content w100" style="display: none;"
									v-if="item.companyType != 'NON'"
								>
                                    <div class="si-title flex-column w220p"
										v-if="item.companyType == 'PUBLIC'"
									>
                                        <div>법인인감증명서<span class="em">*</span></div>
                                        <div>(공동대표 경우 모두 필요)</div>
                                    </div>
									<div class="si-title flex-column w220p"
										v-if="item.companyType == 'PRIVATE'"
									>
                                        <div>대표자명의 인감증명서<span class="em">*</span></div>
                                    </div>
                                    <div class="si-desc flex-aic">
                                        <div class="input-file">
                                            
											<v-file-input
												v-model="item.sealCertificate"
												
												accept="image/png, image/jpeg, image/bmp"
												id="file04" 
												style="display: none;"
											></v-file-input>
                                            <label class="btn-type01" for="file04">첨부파일</label>
                                        </div>
										<div
											v-if="item.sealCertificate"
											>{{ multipleSummary(item.sealCertificate) }}</div>
                                        <div class="ex"
											v-if="!item.sealCertificate">jpg, png만 지원 가능합니다.</div>
                                    </div>
                                </div>
                                <div class="s-content w100">
                                    <div class="si-title w220p"
										v-if="item.companyType == 'PUBLIC'"
										>
                                        법인명의 입금계좌 사본<span class="em">*</span>
                                    </div>
									<div class="si-title w220p"
										v-if="item.companyType == 'PRIVATE'"
										>
                                        대표자명의 또는 <br/> 사업자명의 입금계좌 사본 <span class="em">*</span> 
                                    </div>
									<div class="si-title w220p"
										v-if="item.companyType == 'NON'"
										>
                                        계약자명의 입금계좌 사본<span class="em">*</span>
                                    </div>
                                    <div class="si-desc flex-aic">
                                        <div class="input-file">
                                            
											<v-file-input
												v-model="item.copyOfAccount"
												
												accept="image/png, image/jpeg, image/bmp"
												id="file05" 
												style="display: none;"
											></v-file-input>
                                            <label class="btn-type01" for="file05">첨부파일</label>
                                        </div>
										<div
											v-if="item.copyOfAccount"
											>{{ item.copyOfAccount.name }}</div>
                                        <div class="ex"
											v-if="!item.copyOfAccount">jpg, png만 지원 가능합니다.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="s04">
                        <a 
							v-if="!index"
						@click="save"><div class="btn-submit">등록</div></a>
						<a 
							v-if="index"
						@click="update"><div class="btn-submit">재심사 등록</div></a>
                    </div>
                </div>
    
                <FranchiseeAttach
					v-if="false"
					:item="item"
				/>
            </div>
			
			<FranchiseePopupItem
				v-if="showPopupItem"
				:item="result_item"
				
				@setOverlay="setOverlay"
			/>
			
			<FranchiseePopupLimit
				v-if="showPopupLimit"
				:insurance="codes.insurances"
				@setOverlay="setOverlay"
				@setInsurance="setInsurance"
			/>
				
			<DaumPost
				:overlay="daumPostUp"
				:config="daumConfig"
				
				@callBack="addPost"
			></DaumPost>
			
        </div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	import FranchiseeAttach from '@/view/Franchisee/FranchiseeAttach'
	import FranchiseePopupItem from '@/view/Franchisee/FranchiseePopupItem'
	import FranchiseePopupLimit from '@/view/Franchisee/FranchiseePopupLimit'
	
	import DaumPost from '@/components/Daum/DaumPost'
	
	export default{
		name: "FranchiseeItem"
		,props: ['user', 'codes']
		,components: { FranchiseeAttach, FranchiseePopupItem, FranchiseePopupLimit, DaumPost}
		,data: function(){
			return {
				program: {
					name: '가맹점 상세 정보'
				}
				,index: this.$route.params.index
				,result_item: {
				}
				,item:{
					companyType: 'PUBLIC'
					,isAutoUserId: true
					,businessName: ''
					,businessNumber: ''
					,phoneNumber: ''
					,companyEmail: ''
					,billEmail: ''
					,productName: '취급상품'
					,ceoName: ''
					,ceoBirth: ''
					,managerName: ''
					,managerEmail: ''
					,managerMobilPhoneNumber: ''
					,userId: ''
					,bankCode: ''
					,bankAccountNumber: ''
					,bankAccountHolder: ''
					,servicesType: 'A001'
					,serviceUsage: '판매'
					,oneTimePaymentAmount: '1004'
					,averageMonthlyPaymentAmount: '1004'
					,productImageFile: {}
					,ceoMobilPhoneNumber: ''
					,idCheck: {
						result: false
					}
					,insurance: ''
					,payChnCate: []
					,feeRate: []
					,settlementCycle: '1'
					,insuranceCoverageAmount: ''
					,realOwnerName: '실소유자'
					,realOwnerBirth: '19900815' 
					,realOwnerPhoneNumber: '010123456789'
				}
				,sample: {
					companyType: 'NON'
					,isAutoUserId: true
					,businessName: '비사업자 가맹점1'
					,businessNumber: '1234567890'
					,phoneNumber: '01024892570'
					,companyEmail: 'coehdus@naver.com'
					,billEmail: 'coehdus@naver.com'
					,productName: '라면'
					,ceoName: '비사업자 가맹점1 대표'
					,ceoBirth: '19831121'
					,managerName: '비사업자 가맹점1 담당자'
					,managerEmail: 'coehdus@naver.com'
					,managerMobilPhoneNumber: '01024892570'
					,userId: ''
					,bankCode: '001'
					,bankAccountNumber: '1234567890123'
					,bankAccountHolder: '예금주'
					,servicesType: 'A001'
					,serviceUsage: '판매용'
					,oneTimePaymentAmount: '1000'
					,averageMonthlyPaymentAmount: '100000'
					,ceoMobilPhoneNumber: '01024892570'
					,idCheck: {
						result: false
					}
					,insurance: ''
					,payChnCate: []
					,feeRate: []
					,settlementCycle: '1'
					,insuranceCoverageAmount: ''
					,realOwnerName: '실소유주'
					,realOwnerBirth: '19831121' 
					,realOwnerPhoneNumber: '01024892570'
				}
				,payChnCate: [
					{ code: '001', name: '인증(온라인)', value: 0, use: false}
					,{ code: '002', name: '비인증(수기)', value: 0, use: false }
					,{ code: '003', name: 'OFF PG(단말기)', value: 0, use: false }
				]
				,insurance: {
				}
				,numbers: ['010', '011', '019']
				,personalNumber: {
					fir: '', sec: ''
				}
				,phoneNumbers: {
					ceo: {
						fir: '010', sec: '', thi: ''
					}
					,manager: {
						fir: '010', sec: '', thi: ''
					}
					,owner: {
						fir: '010', sec: '', thi: ''
					}
				}
				,serviceItems: {
					fir: '', sec: '', thi: ''
				}
				,showPopupItem: false
				,showPopupLimit: false
				,daumPostUp: false
				,daumConfig: {
				}
				,errors: {
					email: false
				}
				,rules: {
					max: (obj, part, max) => {
						if(obj[part]){
							if(obj[part].length > max){
								this.$set(obj, part, obj.[part].substring(0, max))
							}
						}
					}
					,numeric: (obj, part) => {
						if(obj[part]){
							const num = obj[part].search(/[0-9]/g);
							if(num){
								this.$set(obj, part, '')
							}
						}
					}
					,demical: (obj, part, {min, max}) => {
						if(obj[part]){
							const temp = obj[part].split('.')
							const num = Array.isArray(temp) ? temp[0] : temp
							const dem = temp[1]
							
							if(num.length > min){
								if(dem){
									this.$set(obj, part, num.substring(0, min) + '.' + dem)
								}else{
									this.$set(obj, part, num.substring(0, min))
								}
							}
							
							if(dem){
								if(dem.length > max){
									this.$set(obj, part, num + '.' + dem.substring(0, max))
								}
							}
						}
					}
					,email: (obj, part) => {
					
						if(obj[part]){
						
							const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
							
							if(pattern.test(obj[part])){
							
								this.errors.email = false
							}else{
								this.errors.email = true
							}
						}else{
							this.errors.email = false
						}
					}
				}
				,companyType: [{ code: 'PUBLIC', name: '법인 사업자'}, { code: 'PRIVATE', name: '개인 사업자'}, { code: 'NON', name: '비사업자'}]
				
			}
		}
		,computed: {
			idCheckStatus: function(){
				let active = 'ready'
				
				if(this.item.isAutoUserId == false && this.item.userId != ''){
					active = 'active'
				}
				
				if(this.item.idCheck.result){
					active = 'confirm'
				}
				
				return active
			}
			,companyTypeText: function(){
				if(this.item.companyType == 'PUBLIC'){
					return '(법인 사업자)'
				}else if(this.item.companyType == 'PRIVATE'){
					return '(개인 사업자)'
				}else{
					return '(비사업자)'
				}
			}
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/merchants/apply'
						,data: this.item
						,authorize: true
						,multipart: true
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '가맹점이 등록되었습니다.'})
						//this.$router.push('/Agent')
			
						this.$emit('setOverlay')
						this.showPopupItem = true
						this.result_item = this.item
					}else{
						this.$emit('setNotify', { tyoe: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { tyoe: 'error', message: E.status})
				}
			}
			,update: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/merchants/apply/' + this.index + '/update'
						,data: this.item
						,authorize: true
						,multipart: true
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '재심사 요청이 등록되었습니다.'})
						this.$router.push({ name: 'MerchantsApplyList'})
					}else{
						this.$emit('setNotify', { tyoe: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { tyoe: 'error', message: E.status})
				}
			}
			,getData: async function(){
				const self = this
				if(this.index && this.index != undefined){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/merchants/apply/' + self.index + '/update'
							,authorize: true
						})
						
						if(result.success){
							this.setItem(result.data.content)
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
					}catch(E){
						this.$emit('setNotify', { type: 'error', message: E })
					}
				}
			}
			,setItem: async function(data){
				
				data.idCheck = await {
					result: false
				}
				
				this.item = await data
				this.item.isAutoUserId = await data.isAutoUserId
				
				this.phoneNumbers = await {
					ceo: {
						fir: data.ceoMobilPhoneNumber.substring(0, 3)
						,sec: data.ceoMobilPhoneNumber.substring(3, 7)
						,thi: data.ceoMobilPhoneNumber.substring(7, 11)
					}
					,manager: {
						fir: data.managerMobilPhoneNumber.substring(0, 3)
						,sec: data.managerMobilPhoneNumber.substring(3, 7)
						,thi: data.managerMobilPhoneNumber.substring(7, 11)
					}
					,owner: {
						fir: data.realOwnerPhoneNumber.substring(0, 3)
						,sec: data.realOwnerPhoneNumber.substring(3, 7)
						,thi: data.realOwnerPhoneNumber.substring(7, 11)
					}
				}
			}
			,idCheck: async function(userId){
				if(userId){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/users/checked/id/' + userId
							,authorize: true
						})
						
						if(result.success){
							this.item.idCheck.result = true
							this.$emit('setNotify', {type: 'success', message: result.message})
						}else{
							this.item.idCheck.result = false
							this.$emit('setNotify', {type: 'error', message: result.message})
						}
					}catch(E){
						this.item.idCheck.result = false
						console.log(E)
						this.$emit('setNotify', {type: 'error', message: E})
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: '아이디를 입력하세요'})
				}
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				this.showPopupItem = false
				this.showPopupLimit = false
			}
			,daumPost: function(type){
				this.$emit('setOverlay', true)
				this.daumPostUp = true
				if(type == 'company'){
					this.daumConfig.post = 'zipCode'
					this.daumConfig.address = 'addressMain'
				}else{
					this.daumConfig.post = 'zipCode2'
					this.daumConfig.address = 'addressMain2'
				}
			}
			,addPost: function(call){
				this.$set(this.item, this.daumConfig.post, call.zonecode)
				this.$set(this.item, this.daumConfig.address, call.address)
				this.daumPostUp = false
				this.$emit('setOverlay')
			}
			,setInsurance: function(index){
				this.item.insuranceCoverageAmount = this.codes.insurances[index].insuranceCoverageAmount
				this.item.oneTimeLimit = this.codes.insurances[index].oneTimeLimit
				this.item.monthLimit  = this.codes.insurances[index].monthLimit 
				this.item.yearLimit = this.codes.insurances[index].yearLimit
				this.setOverlay(false)
			}
			,setInsurance2: function(value){
				
				const index = this.codes.insurances.map(function(o){ return o.insuranceCoverageAmount }).indexOf(value)
				
				if(index > -1){
					this.item.oneTimeLimit = this.codes.insurances[index].oneTimeLimit
					this.item.monthLimit  = this.codes.insurances[index].monthLimit 
					this.item.yearLimit = this.codes.insurances[index].yearLimit
				}else{
					this.item.oneTimeLimit = 0
					this.item.monthLimit  = 0
					this.item.yearLimit = 0
				}
			}
			,getInsurance: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/insurance'
						,authorize: true
					})
					
					if(result.success){
						this.insurance = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E.status})
				}
			}
			,viewPopupLimit: function(){
				this.$emit('setOverlay', true)
				this.showPopupLimit = true
			}
			,multipleSummary: function(item){
				if(item){
					if(Array.isArray(item)){
						if(item.length > 0){
							return item[0].name
						}else{
							return item.name
						}
					}else{
						return item.name
					}
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
		,watch: {
			personalNumber: {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'businessNumber', call.fir + call.sec)
					if(this.item.companyType == 'NON'){
						this.$set(this.item, 'realOwnerBirth', '19' + call.fir)
						this.$set(this.item, 'ceoBirth', '19' + call.fir)
					}
				}
			}
			,'item.userId': {
				handler: function(){
					this.item.isAutoUserId = false
					this.item.idCheck.result = false
				}
			}
			,'phoneNumbers.ceo': {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'ceoMobilPhoneNumber', call.fir + call.sec + call.thi)
					if(this.item.companyType == 'NON'){
						this.$set(this.item, 'realOwnerPhoneNumber', call.fir + call.sec + call.thi)
					}
				}
			}
			,'phoneNumbers.manager': {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'managerMobilPhoneNumber', call.fir + call.sec + call.thi)
				}
			}
			,'phoneNumbers.owner': {
				deep: true
				,handler: function(call){
					this.$set(this.item, 'realOwnerPhoneNumber', call.fir + call.sec + call.thi)
				}
			}
			,'item.ceoName': {
				handler: function(call){
					if(this.item.businessType == 'NON'){
						this.item.realOwnerName = call
					}
				}
			}
			,'item.ceoMobilPhoneNumber': {
				handler: function(call){
					if(this.item.businessType == 'NON'){
						this.item.realOwnerPhoneNumber = call
					}
				}
			}
			,payChnCate: {
				deep: true
				,handler: function(call){

					this.item.payChnCate = []
					this.item.feeRate = []
					for(let i = 0; i < call.length; i++){
						
						if(call[i].use){
							this.item.payChnCate.push(call[i].code)
							this.item.feeRate.push(call[i].value)
						}
					}
				}
			}
			,'item.copyOfIdCard': {
				handler: function(call){
					this.$set(this.item, 'productImageFile', call)
				}
			}
			,'item.businessRegistration': {
				handler: function(call){
					this.$set(this.item, 'productImageFile', call)
					this.$set(this.item, 'sealCertificate', call)
				}
			}
			,'item.companyType': {
				handler: function(call){
					if(call == 'NON'){
						this.$set(this.item,  'ceoName', '')
						this.$set(this.item,  'ceoBirth', '')
						this.$set(this.item,  'ceoMobilPhoneNumber', '')
					}else{
						this.$set(this.item,  'ceoName', '대표자명')
						this.$set(this.item,  'ceoBirth', '19000101')
						this.$set(this.item,  'ceoMobilPhoneNumber', this.item.phoneNumber)
					}
				}
			}
			,'item.phoneNumber': {
				handler: function(call){
					this.$set(this.item, 'ceoMobilPhoneNumber', call)
				}
			}
			,'item.businessName': {
				handler: function(call){
					if(this.item.companyType != 'NON'){
						this.$set(this.item, 'ceoName', call)
					}
					this.$set(this.item, 'ceoBirth', '19000101')
				}
			}
		}
	}
</script>








