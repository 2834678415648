<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div style="color: #00bfa5; font-size: 1.5em; font-weight: bold;">가맹점 등록 신청이 완료되었습니다</div>
					<div class="c01">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">상호명</div>
									<div class="desc">{{ item.businessName }}</div>
								</div>
								<div class="item">
									<div class="title">대표자 휴대폰 번호</div>
									<div class="desc">{{ item.ceoMobilPhoneNumber }}</div>
								</div>
								<div class="item"
									v-if="item.managerMobilPhoneNumber"
								>
									<div class="title">담당자 휴대폰 번호</div>
									<div class="desc">{{ item.managerMobilPhoneNumber }}</div>
								</div>
							</div>
							<div class="p-text" style="font-size: 1.2em;"> <!-- display 숨김처리 class "hide" -->
								<br/>
								가맹점 등록 신청이 완료되었습니다  <br/><br/>
								서류심사 완료에는 영업일 기준 3~5일이 소요됩니다 <br/><br/>
								심사기준에 따라 등록 신청이 반려될수 있으며 사유 확인 후 재심사가 가능합니다<br/><br/>
								감사합니다	<br/><br/>
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<a @click="confirm"><div class="btn-submit">확인</div></a>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default{
		name: 'AgentPopupItem'
		,props: ['item']
		,data: function(){
			return {
				program: {
					name: '가맹점 등록 신청 완료'
				}
			}
		}
		,methods: {
			confirm: function(){
				this.$router.push({ name: 'MerchantsApplyList'})
				this.$emit('setOverlay', false)
			}
		}
	}
</script>