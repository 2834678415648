<template>
	<div class="c02">
		<div class="s01">
			<div class="s01-title">㈜ 립페이 계약 체결 시 아래 구비서류가 필요합니다.</div>
			<div class="p1">아래 필수 구비서류와 계약서는 <span class="underline em">이메일 또는 팩스</span>로 보내주시면 감사하겠습니다.</div>
		</div>
		<div class="s02">
			<div class="table type06 corporate_business active"> <!-- 법인/개인 사업자 class "active" -->
				<div class="item-header">
					<div class="item">
						<div class="td">구비서류</div>
						<div class="td">{{ companyTypeText }}</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item">
						<div class="td">
							립페이 페이먼츠 전자결제 서비스 이용 계약서 2부<br>(인감 날인 / 공동대표일 경우 공동대표 모두 날인 필요)
						</div>
						<a class="td" style="text-align: center;" :href="'/files/download.pdf'" target="_blank" ><div class="btn-download" style="left: 10px;">계약서 다운로드</div></a>
					</div>
					
					<div class="item"
						v-if="item.companyType != 'NON'">
						<div class="td">사업자등록증 사본 1부</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item"
						v-if="item.companyType != 'NON'">
						<div class="td">
							대표자 신분증 사본 또는 주민등록등본 1부<br>(공동대표일 경우 공동대표 모두 필요)
						</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'PUBLIC'">
						<div class="td">인감증명서 원본 1부 (3개월 이내 발급분)</div>
						<div class="td">법인인감증명서</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'PRIVATE'">
						<div class="td">인감증명서 원본 1부 (3개월 이내 발급분)</div>
						<div class="td">사업자명의 인감증명서 또는 <br/>대표자명의 인감증명서</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'PUBLIC'">
						<div class="td">정산 받으실 입금계좌 사본 1부</div>
						<div class="td">법인명의 계좌</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'PRIVATE'">
						<div class="td">정산 받으실 입금계좌 사본 1부</div>
						<div class="td">사업자명의 계좌 또는 <br/>대표자명의 계좌</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'NON'">
						<div class="td">대표자 신분증 사본 또는 주민등록등본 1부</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item"
						v-if="item.companyType == 'NON'">
						<div class="td">
							정산 받으실 입금계좌 사본 1부
						</div>
						<div class="td">계약자명의 계좌</div>
					</div>
				</div>
			</div>

			<div class="table type06 private_business"> <!-- 법인/개인 사업자 class "active" -->
				<div class="item-header">
					<div class="item">
						<div class="td">구비서류</div>
						<div class="td">개인사업자</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item">
						<div class="td">
							립페이 페이먼츠 전자결제 서비스 이용 계약서 2부<br>(인감 날인 / 공동대표일 경우 공동대표 모두 날인 필요)
							<a class="btn-download" :href="'/files/download.pdf'" target="_blank">계약서 다운로드</a>
						</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item">
						<div class="td">사업자등록증 사본 1부</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item">
						<div class="td">
							대표자 신분증 사본 또는 주민등록등본 1부<br>(공동대표일 경우 공동대표 모두 필요)
						</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item">
						<div class="td">법인의 경우 법인인감증명서 / 공동대표 모두 필요</div>
						<div class="td">법인인감증명서</div>
					</div>
					
					<div class="item">
						<div class="td">정산 받으실 입금계좌 사본 1부</div>
						<div class="td">법원명의 계좌</div>
					</div>
				</div>
			</div>

			<div class="table type06 individual_business"> <!-- 비사업자 class "active" -->
				<div class="item-header">
					<div class="item">
						<div class="td">구비서류</div>
						<div class="td">비사업자(개인)</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item">
						<div class="td">
							립페이 페이먼츠 전자결제 서비스 이용 계약서 2부<br>(인감 날인 / 공동대표일 경우 공동대표 모두 날인 필요)
							<div class="btn-download" :href="'/files/download.pdf'" target="_blank">계약서 다운로드</div>
						</div>
						<div class="td">필수</div>
					</div>
					
					<div class="item">
						<div class="td">대표자 신분증</div>
						<div class="td">신분증 사본</div>
					</div>
					
					<div class="item">
						<div class="td">
							정산 받으실 입금계좌 사본 1부
						</div>
						<div class="td">계약자명의 계좌</div>
					</div>
				</div>
			</div>
		</div>
		<div class="s03">
			<div class="item-list">
				<div class="item">
					<div class="item-tit">접수처</div>
					<div class="item-desc">
						서울시 강남구 선릉로 121길 7 2층 주식회사 립페이 PG사업부 계약 담당자앞 <br>070.4160.9115
					</div>
				</div>
				<div class="item">
					<div class="item-tit">등록비 입금계좌</div>
					<div class="item-desc">
						국민은행 / 예금주 : ㈜ 립페이 / 계좌번호 : 624201-04471126 <br>계약한 가맹점명/리셀러명으로 입금해주시기 바랍니다.
					</div>
				</div>
			</div>
		</div>
		<div class="s04">
			<div class="s04-title">꼭 알아두세요!</div>
			<div class="item-list">
				<div class="item">
					<div class="item-tit">01.</div>
					<div class="item-desc">PG계약서 내 상호명, 계약사항(사업자정보, 가맹점정보, 정산정보), (갑)란에 주소, 상호명, 대표자명 작성 후, 인감증명서 상의 등록된 도장으로 날인해주세요.</div>
				</div>
				<div class="item">
					<div class="item-tit">02.</div>
					<div class="item-desc em">사용인감 사용시 사용인감계 必</div>
				</div>
				<div class="item">
					<div class="item-tit">02.</div>
					<div class="item-desc">정상적으로 계약이 체결된 경우 구비서류의 원본은 등기로 보내주셔야 합니다. 등기 관련 내용은 계약 체결 시 별도로 공지드립니다.</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	
	export default{
		name: 'FranchiseeAttach'
		,props: ['item']
		,data: function(){
			return {
			}
		}
		,computed: {
			companyTypeText: function(){
				if(this.item.companyType == 'PUBLIC'){
					return '법인 사업자'
				}else if(this.item.companyType == 'PRIVATE'){
					return '개인 사업자'
				}else{
					return '비사업자'
				}
			}
		}
		,methods: {
		}
		,created: function(){
		}
	}
</script>










